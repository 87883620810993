import useHover from '@react-hook/hover'
import clsx from 'clsx'
import React from 'react'
import { ArticleHeaderView } from '../../generated/content'
import type { ArticleResult } from '../data/fragments/ArticleFragment'
import { normalizeTags } from '../hooks/useTileGridItems'
import { getBaseUrl } from '../utils/getBaseUrl'
import { Container } from './Container'
import style from './Hero.module.sass'
import { HeroContent } from './HeroContent'
import { HeroImages } from './HeroImages'
import { HighlightColorPreset } from './HighlightColor'
import { Link } from './Link'
import { MinAspectRatio } from './MinAspectRatio'
import { PlacedSticker } from './PlacedSticker'
import { richTextToPlaintext } from '../../libs/contember/richTextToPlaintext'

export type HeroProps = {
	linking?: boolean
	article: ArticleResult
	headerView?: ArticleResult['headerView']
}

export const Hero: React.FunctionComponent<HeroProps> = ({ linking, article, ...props }) => {
	const target = React.useRef(null)
	const isHovered = useHover(target, {})

	const headerView = props.headerView ?? article.headerView

	const contentInside = (
		<div
			ref={!!linking && !!article.url ? target : undefined}
			className={style.main}
			data-track-list={
				!!linking &&
				!!article.url &&
				JSON.stringify({
					item: {
						elementId: `article-${article.id}`,
						type: article.promoPartner ? 'promoArticle' : 'article',
						list: 'MainArticle',
						position: 1,
						id: article.id,
						title: article.title ? richTextToPlaintext(article.title) : '',
						author: article.authors.map((author) => {
							return author.name
						}),
						category: article.tags?.map((tag) => {
							return tag.tag?.text
						}),
						systemId: 'fomo',
						url: getBaseUrl() + article.url.url,
					},
				})
			}
			data-track-elementid={`article-${article.id}`}>
			<div className={style.images}>
				{article.mainImage && (
					<HeroImages
						mainImage={article.mainImage}
						secondaryImage1={article.secondaryImage1}
						secondaryImage2={article.secondaryImage2}
						headerView={headerView}
						isHovered={isHovered}
					/>
				)}
			</div>
			<div className={style.content}>
				<HeroContent
					{...article}
					tags={{ items: normalizeTags(article.tags) }}
					headerView={headerView}
				/>
			</div>
			{!!linking && !!article.url && (
				<Link url={article.url} className={style.link} ariaLabel="Přejít na detail článku"></Link>
			)}
		</div>
	)

	return (
		<HighlightColorPreset transparentWhite={headerView === ArticleHeaderView.split}>
			<div className={clsx(style.wrapper, style[`view_${headerView}`])}>
				{headerView === ArticleHeaderView.fullImage ? (
					<MinAspectRatio ratio={21 / 10}>{contentInside}</MinAspectRatio>
				) : (
					<Container>{contentInside}</Container>
				)}
				<div className={style.stickers}>
					<div className={style.stickersCanvas}>
						{article.stickers?.map((sticker) => (
							<PlacedSticker key={sticker.id} placedSticker={sticker} />
						))}
					</div>
				</div>
			</div>
		</HighlightColorPreset>
	)
}
